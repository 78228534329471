export default {
  about: {
    followOnSocials: 'Seko arī sociālos tīklos:',
    openPDF: 'Atvērt visu biogrāfiju (*.pdf)',
    contactMe: 'Sazināties',
  },
  common: {
    theatreCinemaActress: 'Teātra un Kino aktrise',
    showMore: 'Rādīt visu',
    viewAllWorks: 'Skatīt visus darbus',
  },
  footer: {
    home: 'Sākums',
    works: 'Darbi',
    contacts: 'Kontakti',
    allRightsReserved: 'Visas tiesības aizsargātas',
    design: 'Mājaslapas dizains',
  },
  header: {
    worksTitle: 'Darbi',
    worksDescription: 'Apskati zināmāko darbu portfolio',
    contactsTitle: 'Kontakti',
    contactsDescription: 'Lasi biogrāfiju un uzzini vēsturi',
  },
  main: {
    moreInfo: 'Vairāk par mani',
    allWorks: 'Skatīt visus darbus',
  },
  portfolio: {
    allCategories: 'Visi',
  },
  work: {
    share: 'Dalīties',
    allWorks: 'Visi darbi',
    category: 'Kategorija',
    reviews: 'Atsauksmes',
    publications: 'Publikācijas',
    moreInfo: 'Vairāk informācijas',
    nextWork: 'Nākamais darbs',
  },
};
