import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import './ListItem.scss';

import ImagePreloader from '../../UI/ImagePreloader/ImagePreloader';
import ArrowRight from '../../Icons/ArrowRight';

const ListItem = withRouter(({ category, work, history }) => {
  const { cover } = work;
  const coverUrl = (cover || {}).url;
  const ICON_COLOR = '#8A9399';
  const ICON_HOVER_COLOR = '#272829';
  const [iconColor, setIconColor] = useState(ICON_COLOR);
  return (
    <div className="work-item col col-5" onClick={() => history.push(`works/${work._id}`)}>
      <ImagePreloader src={coverUrl} >
        <div className="work-item-cover cover" style={{backgroundImage: `url(${coverUrl})`}}>
          <div className="work-item-hover-overlay cover-overlay small"></div>
        </div>
      </ImagePreloader>
      <div className="work-item-bottom">
        <div className="work-item-content">
          <span className="work-item-category">{category.name}</span>
          <h3 className="work-item-title">{work.title}</h3>
        </div>
        <div className="work-item-icon" 
             onMouseEnter={() => setIconColor(ICON_HOVER_COLOR)} 
             onMouseLeave={() => setIconColor(ICON_COLOR)}>
          <ArrowRight color={iconColor} />
        </div>
      </div>
    </div>
  );
});

export default ListItem;