import React, { useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import MailIcon from '../../images/mail-icon.png';

import './About.scss';

import Loader from '../../components/UI/Loader/Loader';
import Gallery from '../../components/UI/Gallery/Gallery';
import ImagePreloader from '../../components/UI/ImagePreloader/ImagePreloader';
import ArrowRight from '../../components/Icons/ArrowRight';
import { ThemeConsumer } from '../../contexts/ThemeContext';
import WorksList from '../../components/About/WorksList/WorksList';
import ShowMore from '../../components/UI/ShowMore/ShowMore';
import SocialIcons from '../../components/UI/SocialIcons/SocialIcons';
import { COLOR_DARK, COLOR_WHITE } from '../../utils/colors';
import en from '../../translations/en';
import lv from '../../translations/lv';

const vocabularies = { en, lv };

const About = ({ language, container }) => {
  const [arrowColor, setArrowColor] = useState(COLOR_DARK);
  const containerWidth = container.current ? container.current.clientWidth : 1200;
  const query = gql`{
    abouts {
      bio: bio_${language}
      bioFile: bio_file_${language} {
        url
      }
      image {
        url
      }
      gallery {
        images {
          vertical
          file {
            url
          }
        }
      }
      worksLists: workslists {
        title: title_${language}
        works: workslistsitems {
          roleTitle: role_title_${language}
          title: title_${language}
        }
      }
    }
  }`;
  const texts = { ...vocabularies[language].about, ...vocabularies[language].common }
  return (
    <ThemeConsumer>
      {({ footerVisible, updateFooterVisible, headerStyle, updateHeaderStyle }) => {
        if (!footerVisible) updateFooterVisible(true);
        if (headerStyle !== 'default') updateHeaderStyle('default');
        return (
          <div className="about">
            <Query query={query}>
              {({ loading, error, data }) => {
                if (error) return <p>Error</p>;
                if (loading) return <Loader />;
                const about = data.abouts[0];
                const worksLists = about.worksLists;
                // const worksLists = [{size: 12}, {size: 1}, {size: 4}, {size: 3}]
                                  //  .map(sizeObj => Object.assign({}, { title: 'Random title', works: Array.from(Array(sizeObj.size)) }))
                                  //  .map(emptyObj => Object.assign({}, emptyObj, { works: emptyObj.works.map(o => about.worksLists[0].works[0]) }));
                const worksListsMiddle = Math.floor(worksLists.map(list => list.works.length).reduce((acc, curr) => acc + curr) / 2);
                let worksListsSlice = 0;
                worksLists.map(list => list.works.length).reduce((acc, curr, idx) => {
                  if (!worksListsSlice && acc + curr > worksListsMiddle) worksListsSlice = idx;
                  return acc + curr;
                });
                return (
                  <React.Fragment>
                    <div className="row about-top-row">
                      <div className="col col-1"></div>
                      <div className="col col-5">
                        <h1 className="about-title">Ieva Florence</h1>
                        <h2 className="about-subtitle">{texts.theatreCinemaActress}</h2>
                        <div className="about-bio">
                          {about.bio.split('\n').map((paragraph, index) => <p key={index}>{paragraph}</p>)}
                        </div>
                        <a href={about.bioFile.url} className="about-open-bio" rel="noopener noreferrer" target="_blank">{texts.openPDF}</a>
                        <a className="about-contact-me-button" href="mailto:ieva.florence25@gmail.com">
                          <span>{texts.contactMe}</span>
                          <img src={MailIcon} height={24} className="mail-icon" />
                        </a>
                      </div>
                      <div className="col col-1"></div>
                      <div className="col col-4">
                        <ImagePreloader src={about.image.url}>
                          <div className="about-image-cover cover" style={{backgroundImage: `url(${about.image.url})`}}></div>
                        </ImagePreloader>
                      </div>
                      <div className="col col-1"></div>
                      <div className="about-vertical-line"></div>
                    </div>
                    <ShowMore maxHeight={560} language={language}>
                      <div className="row about-works-row">
                        <div className="col col-1"></div>
                        {worksListsSlice > 0 &&
                          <div className="col col-5 about-works-list">
                            {worksLists.slice(0, worksListsSlice).map(list => <WorksList key={list.title} title={list.title} works={list.works} />)}
                          </div>
                        }
                        <div className="col col-5 about-works-list">
                          {worksLists.slice(worksListsSlice, worksLists.length).map(list => <WorksList key={list.title} title={list.title} works={list.works} />)}
                        </div>
                        {worksListsSlice === 0 && <div className="col col-5"></div>}
                      </div>
                    </ShowMore>
                    <div className="row about-gallery-row">
                      <div className="col col-12">
                        <Gallery images={about.gallery.images} orientation="horizontal" preset="sliding" containerWidth={containerWidth} />
                      </div>
                    </div>
                    <div className="about-dark-background">
                    </div>
                    <div className="row about-social-row">
                      <div className="col col-1"></div>
                      <div className="col col-7 about-social-col">
                        <span className="about-follow-me">{texts.followOnSocials}</span>
                        <SocialIcons scale={1.34} />
                      </div>
                      <div className="col col-3 about-all-works">
                        <a href="/portfolio"
                           onMouseEnter={() => setArrowColor(COLOR_WHITE)}
                           onMouseLeave={() => setArrowColor(COLOR_DARK)}
                           className="about-all-works-button">
                          <span>{texts.viewAllWorks}</span>
                          <ArrowRight color={arrowColor} />
                        </a>
                      </div>
                      <div className="col col-1"></div>
                    </div>
                  </React.Fragment>
                );
              }}
            </Query>
          </div>
        );
      }}
    </ThemeConsumer>
  );
};

export default About;
