import React, { useState, useEffect } from 'react';

import './SocialIcons.scss';

import Instagram from '../../Icons/Instagram';
import Facebook from '../../Icons/Facebook';
import Linkedin from '../../Icons/Linkedin';
import IMDB from '../../Icons/IMDB';

const SocialIcons = ({ color = '#8A9399', hoverColor = '#272829', scale = 1 }) => {
  const [linkColors, setLinkColors] = useState((new Array(4)).fill(color));
  // reset link colors if prop color changes
  if (linkColors.every(c => c !== color)) setLinkColors([color, color, color, color])
  return (
    <div className="social-icons">
      <a
        href="https://www.instagram.com/ieva_florence"
        style={{color: linkColors[0]}}
        onMouseEnter={() => setLinkColors([hoverColor, color, color, color])}
        onMouseLeave={() => setLinkColors([color, color, color, color])}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Instagram color={linkColors[0]} scale={scale} />
      </a>
      <a
        href="https://www.facebook.com/ieva.florence"
        style={{color: linkColors[1]}}
        onMouseEnter={() => setLinkColors([color, hoverColor, color, color])}
        onMouseLeave={() => setLinkColors([color, color, color, color])}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Facebook color={linkColors[1]} scale={scale} />
      </a>
      <a
        href="https://www.imdb.com/name/nm7756292"
        style={{color: linkColors[3]}}
        onMouseEnter={() => setLinkColors([color, color, color, hoverColor])}
        onMouseLeave={() => setLinkColors([color, color, color, color])}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IMDB color={linkColors[3]} scale={scale} />
      </a>
    </div>
  );
};

export default SocialIcons;
