import React from 'react';

const ArrowLeft = ({ color }) => {
  const fillColor = color || '#8A9399';
  return (
    <svg width="24px" height="24px">
      <defs>
          <rect id="path-1" x="0" y="0" width="1360" height="461"></rect>
          <rect id="path-3" x="0" y="0" width="1360" height="461"></rect>
      </defs>
      <g id="web" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="03-viens-darbs" transform="translate(-156.000000, -144.000000)">
          <g id="Stacked-Group-Copy-2" transform="translate(156.000000, 144.000000)">
            <g id="icon/arrow-link">
              <rect id="Background" x="0" y="0" width="24" height="24"></rect>
              <path d="M20.4253064,11.493325 L16.5306101,7.06247631 L17.2816976,6.40227369 L22.1964947,11.9936525 L17.2809012,17.5850073 L16.5298681,16.9247427 L20.4257125,12.493325 L2,12.493325 L2,11.493325 L20.4253064,11.493325 Z" id="Combined-Shape" fill={fillColor} fill-rule="nonzero" transform="translate(12.098247, 11.993640) scale(-1, 1) translate(-12.098247, -11.993640) "></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ArrowLeft;