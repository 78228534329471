import React from 'react';

const ArrowRight = ({ color }) => {
  const fillColor = color || '#8A9399';
  return (
    <svg width="32px" height="32px" className="icon">
      <g id="web" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="02-darbi" transform="translate(-588.000000, -682.000000)">
              <g id="Stacked-Group-6" transform="translate(156.000000, 376.000000)">
                  <g transform="translate(0.000000, 292.000000)" id="icon/arrow-link">
                      <g transform="translate(432.000000, 14.000000)">
                          <rect id="Background" x="0" y="0" width="32" height="32"></rect>
                          <path d="M27.2337419,15.3244333 L22.0408134,9.41663508 L23.0422635,8.53636492 L29.5953263,15.9915366 L23.0412016,23.4466764 L22.0398241,22.5663236 L27.2342833,16.6577667 L2.66666667,16.6577667 L2.66666667,15.3244333 L27.2337419,15.3244333 Z" id="Combined-Shape" fill={fillColor} fillRule="nonzero"></path>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  );
}

export default ArrowRight;