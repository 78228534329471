import React from 'react';

const Instagram = ({ color, scale = 1, size = 18 }) => {
  const fillColor = color || '#ffffff';
  const scaledSize = scale * size;
  return (
    <svg width={`${scaledSize}px`} height={`${scaledSize}px`}>
      <g id="web" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="02-darbi">
          <g id="Group">
            <g id="Stacked-Group-3">
              <g id="icon/soc-instagram" transform={`scale(${scale})`}>
                <rect id="Background" x="0" y="0" width="18" height="18"></rect>
                <path d="M9,11.8423976 C10.5718695,11.8423976 11.8441184,10.5686495 11.8441184,8.99717754 C11.8441184,7.42661608 10.5718695,6.15377845 9,6.15377845 C7.42813053,6.15377845 6.15497091,7.42661608 6.15497091,8.99717754 C6.15497091,10.5686495 7.42813053,11.8423976 9,11.8423976 Z M9,4.34285281 C11.5663547,4.34285281 13.6545914,6.4323824 13.6545914,8.99717754 C13.6545914,11.5647041 11.5663547,13.6524127 9,13.6524127 C6.43273463,13.6524127 4.34449785,11.5647041 4.34449785,8.99717754 C4.34449785,6.4323824 6.43273463,4.34285281 9,4.34285281 Z M13.6646092,3.26403642 C14.2802429,3.26403642 14.7802176,3.76206373 14.7802176,4.3784522 C14.7802176,4.9939302 14.2802429,5.49377845 13.6646092,5.49377845 C13.0480648,5.49377845 12.5480901,4.9939302 12.5480901,4.3784522 C12.5480901,3.76206373 13.0480648,3.26403642 13.6646092,3.26403642 Z M16.1895269,12.6355083 C16.1895269,14.5957511 14.5994435,16.1854325 12.6377941,16.1854325 L5.36129522,16.1854325 C3.40055654,16.1854325 1.81047306,14.5957511 1.81047306,12.6355083 L1.81047306,5.36084977 C1.81047306,3.39878604 3.40055654,1.81001517 5.36129522,1.81001517 L12.6377941,1.81001517 C14.5994435,1.81001517 16.1895269,3.39878604 16.1895269,5.36084977 L16.1895269,12.6355083 Z M12.6377941,8.8817842e-14 L5.36129522,8.8817842e-14 C2.40424994,8.8817842e-14 0,2.40455235 0,5.36084977 L0,12.6355083 C0,15.5908953 2.40424994,17.9963581 5.36129522,17.9963581 L12.6377941,17.9963581 C15.5948394,17.9963581 18,15.5908953 18,12.6355083 L18,5.36084977 C18,2.40455235 15.5948394,8.8817842e-14 12.6377941,8.8817842e-14 Z" id="Fill-1" fill={fillColor}></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Instagram;