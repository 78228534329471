import React, { memo } from 'react';
import { LanguageConsumer } from '../../../contexts/LanuageContext';

const LanguageSelection = ({ expanded }) => {
  return (
    <LanguageConsumer>
      {({ language, updateLanguage }) =>
        <div className="col col-1 header-locale">
          <a className={`header-locale-set ${language === 'lv' ? 'active' : ''} ${expanded ? 'expanded' : ''}`} 
             href="javascript:;"
             onClick={() => updateLanguage('lv')}>
            Lv
          </a>
          <a className={`header-locale-set ${language === 'en' ? 'active' : ''} ${expanded ? 'expanded' : ''}`} 
             href="javascript:;" 
             onClick={() => updateLanguage('en')}>
            En
          </a>
        </div>
      }
    </LanguageConsumer>
  );
};

export default memo(LanguageSelection);