import React, { useState } from 'react';

import './HeaderExpanded.scss';

import Times from '../../Icons/Times';
import Logo from '../../Icons/Logo';
import SocialIcons from '../SocialIcons/SocialIcons';
import LanguageSelection from '../../UI/LanguageSelection/LanguageSelection';
import en from '../../../translations/en';
import lv from '../../../translations/lv';

const vocabularies = { en, lv }; 

const HeaderExpanded = ({ main = false, onCollapse, language }) => {
  const TIMEOUT_MS = 4;
  const OPACITY_STEP = 2.5;
  const [opacity, setOpacity] = useState(5);
  const [rendered, setRendered] = useState(false);
  const [timeouts, setTimeouts] = useState([]);
  const increaseOpacity = () => {
    if (opacity !== 100) {
      setOpacity(opacity + OPACITY_STEP);
    } else {
      setRendered(true);
      clearTimeout(timeout);
    }
  };
  const collapseHeader = () => {
    for (let i = 1; i <= 100 / OPACITY_STEP; i++) {
      setTimeouts(timeouts.push(setTimeout(() => decreaseOpacity(OPACITY_STEP * i), TIMEOUT_MS * i)));
      if (i === 100 / OPACITY_STEP) setTimeout(clearAndCollapse, i * TIMEOUT_MS + 1);
    }
  };
  const clearAndCollapse = () => {
    for (let i = 0; i < timeouts.length; i++) clearTimeout(timeouts[i]);
    setTimeouts([]);
    onCollapse();
  }
  const decreaseOpacity = (value) => {
    if (opacity !== 0) setOpacity(opacity - value);
  };
  const timeout = !rendered ? setTimeout(increaseOpacity, TIMEOUT_MS) : null;
  const texts = vocabularies[language].header;
  return (
    <div className={`header header-expanded ${main ? 'main-header' : null}`}>
      <div className="header-items" style={{opacity: `${opacity / 100}`}}>
        <div className="row header-top-row ">
          <div className="col col-1 header-expanded-collapse">
            <a href="javascript:;" onClick={collapseHeader}>
              <Times />
            </a>
          </div>
          <div className="col col-8 header-expanded-logo">
            <a href="/">
              <Logo color="#fff" width={209} height={54} />
            </a>
          </div>
          <div className="col col-2 header-expanded-social-icons">
            <SocialIcons color="#ffffff" hoverColor="#ffffff" />
          </div>
          <LanguageSelection expanded={true} />
        </div>
        <div className="row">
          <div className="col col-12">
            <ul className="header-navigation">
              <li className="header-navigation-link">
                <a href="/portfolio">
                  <span className="header-navigation-link-bigger">{texts.worksTitle}</span>
                  <span className="header-navigation-link-smaller">{texts.worksDescription}</span>
                </a>
              </li>
              <li className="header-navigation-link">
                <a href="/contacts">
                  <span className="header-navigation-link-bigger">{texts.contactsTitle}</span>
                  <span className="header-navigation-link-smaller">{texts.contactsDescription}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderExpanded;