import React from 'react';

const Times = () => {
  return (
    <svg width="24px" height="24px">
      <g id="web" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="01-menu-01" transform="translate(-48.000000, -40.000000)">
          <g id="Group">
            <g id="menu/index">
              <g id="Stacked-Group-3" transform="translate(48.000000, 40.000000)">
                <g id="icon/menu-burger">
                  <path d="M11.2928932,12.0001 L1.74695167,2.45415845 L2.45405845,1.74705167 L12,11.2929932 L21.5459415,1.74705167 L22.2530483,2.45415845 L12.7071068,12.0001 L22.2530483,21.5460415 L21.5459415,22.2531483 L12,12.7072068 L2.45405845,22.2531483 L1.74695167,21.5460415 L11.2928932,12.0001 Z" id="Combined-Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Times;