import React, { memo } from 'react';

import './Footer.scss';

import en from '../../../translations/en';
import lv from '../../../translations/lv';

const vocabularies = { en, lv }; 

const Footer = ({ language }) => {
  const year = new Date().getFullYear();
  const texts = vocabularies[language].footer;
  return (
    <div className="footer">
      <div className="row">
        <div className="col col-4 footer-left">
          <ul>
            <a href="/">{texts.home}</a>
            <a href="/portfolio">{texts.works}</a>
            <a href="/contacts">{texts.contacts}</a>
          </ul>
        </div>
        <div className="col col-8 footer-right">
          <span>&copy; {year} {texts.allRightsReserved}</span>
          <a href="http://www.reinisanzikis.lv" target="_blank" rel="noopener noreferrer" className="footer-by-reinis">{texts.design} reinisanzikis.lv</a>
        </div>
      </div>
    </div>
  );
}

export default memo(Footer);