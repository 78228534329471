import React from 'react';

const Twitter = ({ color }) => {
  const fillColor = color || '#fff';
  return (
    <svg width="18px" height="18px">
      <defs>
          <rect id="path-1" x="0" y="0" width="1360" height="461"></rect>
          <rect id="path-3" x="0" y="0" width="1360" height="461"></rect>
      </defs>
      <g id="web" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="03-viens-darbs" transform="translate(-1142.000000, -341.000000)">
          <g id="Group" transform="translate(1020.000000, 324.000000)">
            <g id="Stacked-Group-7" transform="translate(40.000000, 14.000000)">
              <g id="Stacked-Group-3-Copy" transform="translate(82.000000, 3.000000)">
                <g id="icon/soc-instagram">
                  <rect id="Background" x="0" y="0" width="18" height="18"></rect>
                  <path d="M0.5,14.4119882 C2.04378829,15.414943 3.87667238,16 5.84618966,16 C12.3221024,16 15.9803725,10.457577 15.7595932,5.48585901 C16.441926,4.98860279 17.0326146,4.36639932 17.5,3.65723934 C16.87432,3.93837062 16.2019848,4.12832419 15.4971576,4.21274799 C16.2169811,3.77627691 16.7701789,3.08400169 17.0309483,2.25833685 C16.3561137,2.66357113 15.60963,2.95736598 14.8164911,3.11608274 C14.1799804,2.42887294 13.2735359,2 12.2696153,2 C10.0193335,2 8.36390591,4.12832419 8.87294781,6.33938371 C5.97365842,6.19248628 3.40345504,4.78514141 1.6838765,2.64668637 C0.76993384,4.23554242 1.20982602,6.31405656 2.76361186,7.36513297 C2.19125214,7.34740397 1.65388385,7.18784297 1.18233276,6.92359645 C1.14484195,8.56057408 2.30289145,10.092022 3.9799804,10.4339384 C3.49010047,10.5690165 2.95189904,10.599409 2.40619946,10.4938793 C2.84942416,11.8978472 4.1374418,12.9193753 5.66373438,12.9480794 C4.1974271,14.1131279 2.35037981,14.6331786 0.5,14.4119882" id="Fill-1" fill={fillColor}></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Twitter;