import React from 'react';

import './LinksList.scss';

const LinkList = ({ children, title, links }) => {
  return (
    <div className="links-list">
      <h3 className="links-list-title">
        {children}
        {title}
      </h3>
      <ul className="links-list-ul">
        {links.map(link => <li key={link._id}><a href={link.link}>{link.title}</a></li>)}
      </ul>
    </div>
  );
}

export default LinkList;