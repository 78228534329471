import React from 'react';
import './Gallery.scss';
import CursorRight from './cursor-right.png';
import CursorLeft from './cursor-left.png';
import ImagePreloader from '../ImagePreloader/ImagePreloader';

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.rightOverlay = React.createRef();
    this.state = {
      margin: 0,
      leftScrollIndex: 0,
      rightScrollIndex: 0,
      loadedUrls: {},
      imageWidth: 460,
      imgPadding: 80,
      rightOverlayWidth: 460,
      widthsCalculated: false,
    };
  }

  componentDidMount() {
    const { containerWidth, images } = this.props;
    const mediumBreakpoint = 760;
    const smallScreen = containerWidth < mediumBreakpoint;
    const imageWidth = smallScreen ? 210 : 460;
    const imgPadding = smallScreen ? 40 : 80;

    const rightScrollIndex = Math.round(containerWidth / imageWidth) - 1 > 0 ? Math.round(containerWidth / imageWidth) - 1 : 1;
    const urls = images.map(({ file }) => file.url);
    const loadedUrls = urls.reduce((acc, curr) => ({ ...acc, [curr]: 0 }), {});
    const rightOverlayWidth = this.rightOverlay.current
      ? containerWidth - this.rightOverlay.current.getBoundingClientRect().x
      : imageWidth;

    this.setState({
      imageWidth,
      rightScrollIndex,
      imgPadding,
      loadedUrls,
      rightOverlayWidth,
    });
  }

  componentDidUpdate(_, prevState) {
    const { containerWidth } = this.props;
    const { rightClickableIndex } = prevState;
    const { imageWidth, widthsCalculated } = this.state;

    if (this.rightOverlay.current && !widthsCalculated) {
      const rightOverlayWidth = this.rightOverlay.current
        ? containerWidth - this.rightOverlay.current.getBoundingClientRect().x
        : imageWidth;
      this.setState({
        rightOverlayWidth,
        widthsCalculated: true,
      });
    }

    return true;
  }

  onRightClick = () => {
    this.setState((prevState) => ({
      leftScrollIndex: prevState.leftScrollIndex + 1,
      rightScrollIndex: prevState.rightScrollIndex + 1,
      margin: prevState.margin - prevState.imageWidth - prevState.imgPadding,
    }));
  }

  onLeftClick = () => {
    this.setState((prevState) => ({
      leftScrollIndex: prevState.leftScrollIndex - 1,
      rightScrollIndex: prevState.rightScrollIndex - 1,
      margin: prevState.margin + prevState.imageWidth + prevState.imgPadding,
    }));
  }

  handleImageLoad = (src) => {
    const { loadedUrls } = this.state;
    const { onLoad } = this.props;
    const updatedUrls = { ...loadedUrls, [src]: 1 };
    if (!Object.values(loadedUrls).find(el => el === 0) && onLoad) {
      onLoad();
    }
    this.setState({
      loadedUrls: updatedUrls,
    })
  };

  renderImage = ({ vertical, file }, index) => {
    const { orientation, preset } = this.props;
    const {
      margin,
      leftScrollIndex,
      rightScrollIndex,
      imgPadding,
      rightOverlayWidth,
    } = this.state;
    const leftClickable = orientation === 'horizontal' && (index !== 0 && index / leftScrollIndex === 1);
    const rightClickable = orientation === 'horizontal' && (index / rightScrollIndex === 1);
    const marginStyle = preset === 'sliding' ? { margin: `0 ${-margin + imgPadding}px 0 ${margin}px` } : {};

    return (
      <ImagePreloader
        src={file.url}
        key={file.url}
        cssClass={vertical ? 'vertical' : 'horizontal'}
        onLoad={this.handleImageLoad}
      >
        <div className={`gallery-image ${preset} ${vertical ? 'vertical' : 'horizontal'} ${leftClickable || rightClickable ? 'clickable' : ''}`}
            style={{...marginStyle, backgroundImage: `url(${file.url})` }}
            key={`gimg-${index}`}>
          {leftClickable &&
            <div className="gallery-clickable-overlay left"
              onClick={this.onLeftClick}
            >
              <img src={CursorLeft} />
            </div>
          }
          {rightClickable &&
            <div ref={this.rightOverlay}
              style={{ width: rightOverlayWidth }}
              className="gallery-clickable-overlay right"
              onClick={this.onRightClick}
            >
              <img src={CursorRight} />
            </div>
          }
        </div>
      </ImagePreloader>
    );
  }

  render() {
    const { orientation, preset, images } = this.props;
    const {
      margin,
      leftScrollIndex,
      rightScrollIndex,
      imgPadding,
      rightOverlayWidth,
    } = this.state;

    return (
      <div className={`gallery ${orientation} ${preset}`}>
        {images.map(this.renderImage)}
        {orientation === 'horizontal' && <div className="gallery-buffer"></div>}
      </div>
    );
  }
}

export default Gallery;
