import React, { memo } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { LanguageConsumer } from '../../../contexts/LanuageContext';

const Filters = ({ filter, allLabel, filters, setFilters, onChange }) => {
  const query = gql`{
    categories {
      name_lv,
      name_en
    }
  }`;
  return (
    <LanguageConsumer>
      {({ language }) => {
        return <Query query={query}>
          {({ loading, error, data }) => {
            if (loading || error) return null;
            let { categories } = data;
            categories = [allLabel, ...categories.map(cat => cat[`name_${language}`])];
            if (filters.join(', ') !== categories.join(', ')) setFilters(categories);
            return (
              <ul className="portfolio-filters col col-5">
                {filters.map(f => (
                  <li key={f} 
                      className={`filter ${filter === f ? 'active' : ''}`}
                      onClick={() => onChange(f)}>
                    {f}
                  </li>
                ))}
              </ul>
            );
          }}
        </Query>
      }}
    </LanguageConsumer>
  );
}

export default memo(Filters);