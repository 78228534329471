import React from 'react';

import './WorksList.scss';

const WorksList = ({ title, works }) => {
  return (
    <div className="works-list">
      <h5 className="works-list-title">{title}</h5>
      <ul>
        {works.map(work => {
          return (
            <li key={work.title} className="works-list-item">
              <div className="works-list-item-dash">––</div>
              <div>
                <h6 className="works-list-role-title">{work.roleTitle}</h6>
                <span className="works-list-work-title">{work.title}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default WorksList;