import React, { useState } from 'react';

import './Header.scss';

import MenuBurger from '../../Icons/MenuBurger';
import HeaderExpanded from '../HeaderExpanded/HeaderExpanded';
import Logo from '../../Icons/Logo';
import SocialIcons from '../SocialIcons/SocialIcons';
import LanguageSelection from '../../UI/LanguageSelection/LanguageSelection';
import { COLOR_WHITE, COLOR_LIGHT, COLOR_DARK, COLOR_LIGHTER } from '../../../utils/colors';

const Header = ({ style, language, container }) => {
  const [expanded, setExpanded] = useState(false);
  const main = style === 'main';
  const white = style === 'white' || style === 'white-light';
  const light = style === 'light' || style === 'white-light';
  let logoWidth = 209;
  let logoHeight = 54;

  if (container.current) {
    logoWidth = container.current.clientWidth >= 760 ? 209 : 155;
    logoHeight = container.current.clientWidth >= 760 ? 54 : 40;
  }

  const baseLogoColor = white ? COLOR_WHITE : COLOR_DARK;
  const hoverLogoColor = COLOR_LIGHTER;
  const [logoColor, setLogoColor] = useState(baseLogoColor);
  if (logoColor !== baseLogoColor && logoColor !== hoverLogoColor) setLogoColor(baseLogoColor);

  const baseBurgerColor = white ? COLOR_WHITE : COLOR_DARK;
  const hoverBurgerColor = COLOR_LIGHTER;
  const [burgerColor, setBurgerColor] = useState(baseBurgerColor);
  if (burgerColor !== baseBurgerColor && burgerColor !== hoverBurgerColor) setBurgerColor(baseBurgerColor);

  if (expanded) {
    return (
    <HeaderExpanded main={main}
                    language={language}
                    onCollapse={() => setExpanded(false)} />
    );
  }
  return (
    <div className={`header ${main ? 'main-header' : ''} ${white ? 'white-header' : ''}`}>
      <div className="row">
        <div className={`col ${main ? 'col-11 header-expand-it' : 'col-1 header-expand-it'}`}>
          <a href="javascript:;" 
             onMouseEnter={() => setBurgerColor(hoverBurgerColor)}
             onMouseLeave={() => setBurgerColor(baseBurgerColor)}
             onClick={() => setExpanded(true)}>
            <MenuBurger color={burgerColor} />
          </a>
        </div>
        <div className="col col-8 header-logo">
          <a href="/"
             onMouseEnter={() => setLogoColor(hoverLogoColor)}
             onMouseLeave={() => setLogoColor(baseLogoColor)}>
            <Logo color={logoColor} 
                  width={logoWidth} 
                  height={logoHeight} />
          </a>
        </div>
        <div className="col col-2 header-social-icons">
          <SocialIcons color={white ? COLOR_WHITE : COLOR_DARK} hoverColor={COLOR_LIGHTER} />
        </div>
        <LanguageSelection />
      </div>
    </div>
  );
};

export default Header;