import React from 'react';

const MenuBurger = ({ color }) => {
  return (
    <svg width="24px" height="24px">
      <g id="web" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="04-contacts-open" transform="translate(-48.000000, -40.000000)">
          <g id="Group">
            <g id="Stacked-Group-3" transform="translate(48.000000, 40.000000)">
              <g id="icon/menu-burger">
                <rect id="Background" x="0" y="0" width="24" height="24"></rect>
                <path d="M0,18.5001 L0,17.5001 L24,17.5001 L24,18.5001 L0,18.5001 Z M0,12.5001 L0,11.5001 L24,11.5001 L24,12.5001 L0,12.5001 Z M0,6.5001 L0,5.5001 L24,5.5001 L24,6.5001 L0,6.5001 Z" id="Combined-Shape" fill={color} fillRule="nonzero"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MenuBurger;