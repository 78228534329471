import React, { createContext } from 'react';

const LANGS = ['lv', 'en'];

const LanguageContext = createContext({
  language: '',
  updateLanguage: () => {},
});

export class LanguageProvider extends React.Component {
  updateLanguage = l => {
    const lang = LANGS.includes(l) ? l : LANGS[0]
    this.setState({ language: lang });
    localStorage.setItem('language', lang);
  };

  state = {
    language: localStorage.getItem('language') || LANGS[0],
    updateLanguage: this.updateLanguage,
  };

  render() {
    return (
      <LanguageContext.Provider value={this.state}>
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}

export const LanguageConsumer = LanguageContext.Consumer;