export default {
  about: {
    followOnSocials: 'Follow me on social media',
    openPDF: 'Open the full biography (*.pdf)',
    contactMe: 'Get in touch',
  },
  common: {
    theatreCinemaActress: 'Film and Theater actress',
    showMore: 'Show more',
    viewAllWorks: 'View all works',
  },
  footer: {
    home: 'Home',
    works: 'Portfolio',
    contacts: 'Contacts',
    allRightsReserved: 'All rights reserved',
    design: 'Designed by',
  },
  header: {
    worksTitle: 'Portfolio',
    worksDescription: 'View my most well known work',
    contactsTitle: 'Contacts',
    contactsDescription: 'Learn more about my biography',
  },
  main: {
    moreInfo: 'More about me',
    allWorks: 'View my work',
  },
  portfolio: {
    allCategories: 'All',
  },
  work: {
    share: 'Share',
    allWorks: 'All works',
    category: 'Category',
    reviews: 'Reviews',
    publications: 'Publications',
    moreInfo: 'More information',
    nextWork: 'Next work',
  },
};
