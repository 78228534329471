import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ThemeProvider, ThemeConsumer } from './contexts/ThemeContext';
import { LanguageProvider, LanguageConsumer } from './contexts/LanuageContext';
import { client } from './apollo';

import './App.scss';

import MainScreen from './screens/Main/Main';
import PortfolioScreen from './screens/Portfolio/Portfolio';
import WorkScreen from './screens/Work/Work';
import AboutScreen from './screens/About/About';
import Footer from './components/UI/Footer/Footer';
import Header from './components/UI/Header/Header';

const FooterContext = React.createContext();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  render() {
    return (
      // that's a lot of providers
      <LanguageProvider>
        <LanguageConsumer>
          {({ language }) => (
            <ApolloProvider client={client}>
              <ThemeProvider>
                <ThemeConsumer>
                  {({ headerStyle }) => <Header style={headerStyle} language={language} container={this.container} />}
                </ThemeConsumer>
                <ThemeConsumer>
                {({ headerStyle }) =>
                  <div className={`container ${headerStyle}`} ref={this.container}>
                    <BrowserRouter>
                      <Switch>
                        <Route exact path="/" component={({ history }) => <MainScreen language={language} container={this.container} history={history} />} />
                        <Route exact path="/portfolio" component={() => <PortfolioScreen language={language} />} />
                        <Route path="/works/:wid" component={({ match }) => <WorkScreen language={language} match={match} />} />
                        <Route path="/contacts" component={() => <AboutScreen language={language} container={this.container} />} />
                      </Switch>
                    </BrowserRouter>
                  </div>
                }
                </ThemeConsumer>
                <ThemeConsumer>
                  {({ footerVisible }) => footerVisible && <Footer language={language} />}
                </ThemeConsumer>
              </ThemeProvider>
            </ApolloProvider>
          )}
        </LanguageConsumer>
      </LanguageProvider>
    );
  }
}

export default App;
