import React, { createContext } from 'react';

const HEADER_STYLES = ['main', 'white', 'default', 'white-light'];

const ThemeContext = createContext({
  footerVisible: false,
  headerStyle: '',
  updateFooterVisible: () => {},
  updateHeaderStyle: () => {},
});

export class ThemeProvider extends React.Component {
  updateFooterVisible = visibility => {
    this.setState({ footerVisible: visibility });
    return true;
  };

  updateHeaderStyle = style => {
    const hStyle = HEADER_STYLES.includes(style) ? style : HEADER_STYLES[0];
    this.setState({ headerStyle: hStyle });
    return true;
  };

  state = {
    footerVisible: true,
    headerStyle: HEADER_STYLES[0],
    updateFooterVisible: this.updateFooterVisible,
    updateHeaderStyle: this.updateHeaderStyle,
  };

  render() {
    return (
      <ThemeContext.Provider value={this.state}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

export const ThemeConsumer = ThemeContext.Consumer;