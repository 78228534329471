import React, { useState } from 'react';

import './ImagePreloader.scss';

const Image = ({ src, cssClass, children, onLoad, onError }) => {
  const [imageStatus, setImageStatus] = useState('loading');
  const handleLoad = () => {
    if (onLoad) onLoad(src);
    setImageStatus('ready');
  };
  const handleError = () => {
    if (onError) onError(src);
    setImageStatus('error');
  };

  if (imageStatus === 'loading') return (
    <div className={`dummy-image ${cssClass}`}>
      <img src={src}
           alt={imageStatus}
           height="1"
           onLoad={handleLoad}
           onError={handleError}
      />
    </div>
  );
  if (imageStatus === 'error') return <div>Error</div>
  return (
    <React.Fragment>{children}</React.Fragment>
  );
}

export default Image;
