import config from './config';
// import ApolloClient from 'apollo-boost';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';

const PRODUCTION_HOSTS = [
  "www.ievaflorence.lv",
  "ievaflorence.lv",
];

const uri = PRODUCTION_HOSTS.includes(window.location.hostname) ? config.staging.uri : config.development.uri;
// const uri = config.development.uri;

export const client = new ApolloClient({
  link: createHttpLink({ uri, fetchOptions: { method: "GET" }, }),
  cache: new InMemoryCache()
});
