import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import axios from 'axios';
import './Work.scss';

import Loader from '../../components/UI/Loader/Loader';
import Gallery from '../../components/UI/Gallery/Gallery';
import LinksList from '../../components/Work/LinksList';
// Icons
import TwitterIcon from '../../components/Icons/Twitter';
import FacebookIcon from '../../components/Icons/Facebook';
import ArrowLeft from '../../components/Icons/ArrowLeft';
import ArrowRight from '../../components/Icons/ArrowRight';
import ReviewBubble from '../../components/Icons/ReviewBubble';
import ReviewWeb from '../../components/Icons/ReviewWeb';
import { ThemeConsumer } from '../../contexts/ThemeContext';
import { COLOR_WHITE, COLOR_LIGHT, COLOR_LIGHTER, COLOR_DARK } from '../../utils/colors';
import en from '../../translations/en';
import lv from '../../translations/lv';

const vocabularies = { en, lv };

const YOUTUBE_URL = 'youtube.com';
const VIMEO_URL = 'vimeo.com';
const YOUTUBE_ASPECT_RATIO = 0.57;
const VIMEO_ASPECT_RATIO = 0.56;

const renderVideoIframe = async (videoLink, ref, callback) => {
  if (!videoLink) return callback(null);

  if (videoLink.includes(YOUTUBE_URL)) {
    await new Promise(resolve => setTimeout(resolve, 800));
    let videoId = videoLink.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
    const ytContainerWidth = ref.current ? ref.current.clientWidth - 30 : 600;
    const ytContainerHeight = ytContainerWidth * YOUTUBE_ASPECT_RATIO;
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    const iframeHTML = `<iframe src="${embedUrl}" width="${ytContainerWidth}" height=${ytContainerHeight} />`;

    return callback(iframeHTML);
  }

  if (videoLink.includes(VIMEO_URL)) {
    const vimeoEmbed = await axios.get(`https://vimeo.com/api/oembed.json?url=${videoLink}`);

    if (!vimeoEmbed) return null;

    const { html } = vimeoEmbed.data;
    const vimeoContainerWidth = ref.current ? ref.current.clientWidth - 30 : 600;
    const vimeoContainerHeight = vimeoContainerWidth * VIMEO_ASPECT_RATIO;

    return callback(html.replace(/height="[0-9]*"/, `height="${vimeoContainerHeight}"`));
  }

  return callback(null);
};

const Work = ({ match, language }) => {
  let galleryContainer = React.createRef();
  const [rightIconColor, setRightIconColor] = useState(COLOR_LIGHTER);
  const [leftIconColor, setLeftIconColor] = useState(COLOR_WHITE);
  const [facebookIconColor, setFacebookIconColor] = useState(COLOR_WHITE);
  const [twitterIconColor, setTwitterIconColor] = useState(COLOR_WHITE);
  const [facebookIconColor2, setFacebookIconColor2] = useState(COLOR_LIGHT);
  const [twitterIconColor2, setTwitterIconColor2] = useState(COLOR_LIGHT);
  const [portfolioButtonColor, setPortfolioButtonColor] = useState(COLOR_DARK);
  const [randomButtonColor, setRandomButtonColor] = useState(COLOR_WHITE);
  const [videoIframe, setVideoIframe] = useState(null);
  const texts = {...vocabularies[language].work, ...vocabularies[language].common};
  const query = gql`
    {
      works {
        _id
        title: title_${language}
        content: content_${language}
        description: description_${language}
        roleTitle: role_title_${language}
        roleDescription: role_description_${language}
        view_more_link
        videoLink: video_link
        reviews {
          _id
          title: title_${language}
          link
        }
        publications {
          _id
          title: title_${language}
          link
        }
        category {
          name: name_${language}
        }
        cover {
          url
        }
        gallery {
          images {
            vertical
            file {
              url
            }
          }
        }
      }
    }
  `;

  return (
    <ThemeConsumer>
      {({ footerVisible, updateFooterVisible, headerStyle, updateHeaderStyle }) => {
        if (!footerVisible) updateFooterVisible(true);
        if (headerStyle !== 'white-light') updateHeaderStyle('white-light');
        return (
          <div className="work">
            <Query
              query={query}
            >
              {({ loading, error, data }) => {
                if (loading) return <Loader />;
                if (error) return <p>Error</p>;
                const { works } = data;
                const workIndex = works.findIndex(work => work._id === match.params.wid);
                const nextWorkIndex = workIndex + 1 === works.length ? 0 : workIndex + 1;
                const work = works[workIndex];
                const nextWork = works[nextWorkIndex];
                const showImages = work.gallery && work.gallery.images;
                const shareURLFacebook = `https://www.facebook.com/sharer/sharer.php?href=${encodeURI(window.location.href)}&description=${work.title}`;
                const shareURLTwitter = `https://twitter.com/intent/tweet?text=${work.title}&url=${window.location.href}`;

                renderVideoIframe(work.videoLink, galleryContainer, setVideoIframe);

                return (
                <div className="work-row row">
                  <Helmet>
                    <title>Ieva Florence | {work.title}</title>
                    <meta name="description" content={work.roleDescription} />
                  </Helmet>
                  <div className="work-cover cover" style={{backgroundImage: `url(${(work.cover || {}).url})`}}>
                    <div className="work-cover-overlay cover-overlay"></div>
                  </div>
                  <div className="work-on-cover col col-10">
                    <a className="with-icon left work-all-works"
                      style={{color: leftIconColor}}
                      href="/portfolio"
                      onMouseEnter={() => setLeftIconColor(COLOR_LIGHTER)}
                      onMouseLeave={() => setLeftIconColor(COLOR_WHITE)}>
                      <ArrowLeft color={leftIconColor} />
                      <span>{texts.allWorks}</span>
                    </a>
                    <div className="work-category">
                      <strong>{texts.category}: </strong><span>{work.category.name}</span>
                    </div>
                    <div className="work-title-share">
                      <h1 className="work-title">{work.title}</h1>
                      <div className="work-share">
                        <span>{texts.share}:</span>
                        <a href={shareURLTwitter}
                           onMouseEnter={() => setTwitterIconColor(COLOR_LIGHTER)}
                           onMouseLeave={() => setTwitterIconColor(COLOR_WHITE)}
                           target="_blank"
                           rel="noopener noreferrer">
                          <TwitterIcon color={twitterIconColor} />
                        </a>
                        <a href={shareURLFacebook}
                           onMouseEnter={() => setFacebookIconColor(COLOR_LIGHTER)}
                           onMouseLeave={() => setFacebookIconColor(COLOR_WHITE)}
                           target="_blank"
                           rel="noopener noreferrer">
                          <FacebookIcon color={facebookIconColor} />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="work-gallery col col-8" ref={galleryContainer}>
                    <div dangerouslySetInnerHTML={{__html: videoIframe}} />
                    {showImages && <Gallery images={work.gallery.images} />}
                  </div>
                  <ul className="work-content col col-8">
                    {work.content.split('\n').map((line, index) => (
                      <li key={`cl-${index}`}>{line}</li>
                    ))}
                  </ul>
                  <div className="work-description col col-8">
                    {work.description.split('\n').map((paragraph, index) => (
                      <p key={`dp-${index}`}>{paragraph}</p>
                    ))}
                    <a className="with-icon right"
                      style={{color: rightIconColor}}
                      href={work.view_more_link}
                      onMouseEnter={() => setRightIconColor(COLOR_DARK)}
                      onMouseLeave={() => setRightIconColor(COLOR_LIGHT)}>
                      <span>{texts.moreInfo}</span>
                      <ArrowRight color={rightIconColor} />
                    </a>
                  </div>
                  <div className="work-role col col-8">
                    <h5>{work.roleTitle}</h5>
                    {work.roleDescription.split('\n').map((paragraph, index) => (
                      <p key={`rp-${index}`}>{paragraph}</p>
                    ))}
                  </div>
                  <div className="col col-8">
                    <LinksList title={texts.reviews} links={work.reviews}>
                      <ReviewBubble />
                    </LinksList>
                    <LinksList title={texts.publications} links={work.publications}>
                      <ReviewWeb />
                    </LinksList>
                  </div>
                  <div className="work-footer">
                    <div className="col col-10">
                      <a href="/portfolio"
                        className="with-icon left work-button"
                        style={{color: portfolioButtonColor, backgroundColor: portfolioButtonColor === COLOR_DARK ? 'transparent' : COLOR_DARK}}
                        onMouseEnter={() => setPortfolioButtonColor(COLOR_WHITE)}
                        onMouseLeave={() => setPortfolioButtonColor(COLOR_DARK)}>
                        <ArrowLeft color={portfolioButtonColor} />
                        <span>{texts.allWorks}</span>
                      </a>
                      <a href={`/works/${nextWork._id}`}
                        className="with-icon right work-button"
                        style={{color: randomButtonColor, backgroundColor: randomButtonColor === COLOR_DARK ? 'transparent' : COLOR_DARK}}
                        onMouseEnter={() => setRandomButtonColor(COLOR_DARK)}
                        onMouseLeave={() => setRandomButtonColor(COLOR_WHITE)}>
                        <span>{texts.nextWork}</span>
                        <ArrowRight color={randomButtonColor} />
                      </a>
                      <div className="work-share share-left">
                        <span>{texts.share}:</span>
                        <a href={shareURLTwitter}
                           onMouseEnter={() => setTwitterIconColor2(COLOR_DARK)}
                           onMouseLeave={() => setTwitterIconColor2(COLOR_LIGHT)}
                           target="_blank"
                           rel="noopener noreferrer">
                          <TwitterIcon color={twitterIconColor2} />
                        </a>
                        <a href={shareURLFacebook}
                           onMouseEnter={() => setFacebookIconColor2(COLOR_DARK)}
                           onMouseLeave={() => setFacebookIconColor2(COLOR_LIGHT)}
                           target="_blank"
                           rel="noopener noreferrer">
                          <FacebookIcon color={facebookIconColor2} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                );
              }}
            </Query>
          </div>
        );
      }}
    </ThemeConsumer>
  );
}

export default Work;
