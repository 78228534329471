import React from 'react';

import './ShowMore.scss';

import en from '../../../translations/en';
import lv from '../../../translations/lv';

const vocabularies = { en, lv }; 

class ShowMore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      moreShown: false,
    };

    this.div = React.createRef();
    this.toggleSetMore = this.toggleSetMore.bind(this);
  }

  toggleSetMore() {
    this.setState({
      moreShown: !this.state.moreShown,
    });
  }

  componentDidMount() {
    const height = this.div.current.clientHeight;
    if (height < this.props.maxHeight) this.toggleSetMore();
  }

  render() {
    const { maxHeight, children, language } = this.props;
    const { moreShown } = this.state;
    const texts = vocabularies[language].common;
    return (
      <div className={`show-more ${moreShown ? '' : 'collapsed'}`} 
           style={{ maxHeight: moreShown ? null : `${maxHeight}px` }}
           ref={this.div}
      >
        {children}
        <div className="show-more-button-container">
          <button className="show-more-button"
                  onClick={this.toggleSetMore}>
            {texts.showMore}
          </button>
        </div>
      </div>
    );
  }
};

export default ShowMore;