import React, { useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';

import './Main.scss';

import Gallery from '../../components/UI/Gallery/Gallery';
import Logo from '../../components/Icons/Logo';
import ArrowRight from '../../components/Icons/ArrowRight';
import SocialIcons from '../../components/UI/SocialIcons/SocialIcons';
import Loader from '../../components/UI/Loader/Loader';
import { ThemeConsumer } from '../../contexts//ThemeContext';
import { COLOR_WHITE, COLOR_DARK } from '../../utils/colors';
import en from '../../translations/en';
import lv from '../../translations/lv';

const vocabularies = { en, lv };

const Main = ({ language, container, history }) => {
  let logoWidth = 352;
  let logoHeight = 90;
  let containerWidth = 1200;
  if (container.current) {
    logoWidth = container.current.clientWidth <= 760 ? 288 : 352;
    logoHeight = container.current.clientWidth <= 760 ? 74 : 90;
    containerWidth = container.current.clientWidth;
  }
  const [buttonColor, setButtonColor] = useState(COLOR_DARK);
  const [linkColor, setLinkColor] = useState(COLOR_WHITE);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  let about = null;
  const query = gql`{
    abouts {
      gallery {
        images {
          vertical
          file {
            url
          }
        }
      }
    }
  }`;
  const texts = {...vocabularies[language].main, ...vocabularies[language].common};
  return (
    <div className="main">
    <Query query={query}>
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error || !data.abouts) return <div className="error">ERROR</div>;
        about = data.abouts[0];
        return (
          <ThemeConsumer>
            {({ headerStyle, updateHeaderStyle }) => {
              if (headerStyle !== 'main') updateHeaderStyle('main');
              return (
                <React.Fragment>
                  <div style={{ display: !imagesLoaded ? 'block' : 'none' }}>
                    <Loader />
                  </div>
                  <div className="main-content" style={{ display: !imagesLoaded ? 'none' : 'block' }}>
                    <div className="main-header-row row">
                      <div className="col col-4">
                        <Logo height={logoHeight} width={logoWidth} />
                        <h1>{texts.theatreCinemaActress}</h1>
                      </div>
                      <div className="col col-6">
                        <SocialIcons />
                      </div>
                    </div>
                    <div className="row main-gallery-row">
                      <div className="col col-12">
                        <Gallery
                          images={about.gallery.images}
                          orientation="horizontal"
                          preset="sliding"
                          containerWidth={container.current.clientWidth}
                          onLoad={() => setImagesLoaded(true)}
                        />
                      </div>
                    </div>
                    <div className="row main-buttons-row">
                      <div className="col col-10">
                        <a href="/portfolio"
                          className="with-icon right work-button"
                          style={{color: buttonColor, backgroundColor: buttonColor === '#ffffff' ? COLOR_DARK : 'transparent'}}
                          onMouseEnter={() => setButtonColor(COLOR_WHITE)}
                          onMouseLeave={() => setButtonColor(COLOR_DARK)}
                        >
                            <span>{texts.allWorks}</span>
                            <ArrowRight color={buttonColor} />
                        </a>
                        <a href="/contacts"
                          className="with-icon right work-button"
                          style={{color: linkColor, backgroundColor: linkColor === '#ffffff' ? COLOR_DARK : 'transparent'}}
                          onMouseEnter={() => setLinkColor(COLOR_DARK)}
                          onMouseLeave={() => setLinkColor(COLOR_WHITE)}
                        >
                          <span>{texts.moreInfo}</span>
                          <ArrowRight color={linkColor} />
                        </a>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            }}
          </ThemeConsumer>
        );}}
    </Query>
    </div>
  );
};

export default Main;
