import React, { useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import './Portfolio.scss';

import Filters from '../../components/Portfolio/Filters/Filters';
import ListItem from '../../components/Portfolio/ListItem/ListItem';
import Loader from '../../components/UI/Loader/Loader';
import { ThemeConsumer } from '../../contexts/ThemeContext';
import en from '../../translations/en';
import lv from '../../translations/lv';

const vocabularies = { en, lv };

const formatOrder = (val) => {
  if (!val) return 999;

  return parseInt(val, 10);
}

const Portfolio = ({ language }) => {
  const texts = vocabularies[language].portfolio;
  const [filters, setFilters] = useState([]);
  const [filter, setFilter] = useState(texts.allCategories);
  const filterInQuery = filter === texts.allCategories ? filters.filter(f => f !== 'All').join('", "') : filter;
  const query = gql`{
    categories(where: { name_${language}: ["${filterInQuery}"] }) {
      name: name_${language}
      works(sort: "order:asc") {
        _id
        title: title_${language}
        cover {
          url
        }
        order
        category {
          name_${language}
        }
      }
    }
  }`;

  return (
    <ThemeConsumer>
      {({ footerVisible, updateFooterVisible, headerStyle, updateHeaderStyle }) => {
        if (!footerVisible) updateFooterVisible(true);
        if (headerStyle !== 'default') updateHeaderStyle('default');
        return (
          <div className="portfolio">
            <div className="row">
              <h1 className="portfolio-title col col-5">
                Works
              </h1>
              <Filters allLabel={texts.allCategories}
                       filter={filter}
                       onChange={setFilter}
                       filters={filters}
                       setFilters={setFilters} />
            </div>
            <div className="portfolio-row row">
              <Query
                query={query}
              >
                {({ loading, error, data }) => {
                  if (loading) return <Loader />;
                  if (error) return <p>Error</p>;

                  const works = data.categories
                    .map(category => category.works)
                    .reduce((acc, curr) => [...acc, ...curr], []);
                  const sortedWorks = works.sort((a, b) => formatOrder(a.order) - formatOrder(b.order));

                  return sortedWorks.map((work) => (
                    <ListItem key={work._id} category={work.category} work={work} />
                  ));
                }}
              </Query>
            </div>
          </div>
        );
      }}
    </ThemeConsumer>
  );
};

export default Portfolio;
