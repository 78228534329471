import React from 'react';

const Facebook = ({ color, scale = 1, size = 18 }) => {
  const fillColor = color || '#fff';
  const scaledSize = scale * size;
  return (
    <svg width={`${scaledSize}px`} height={`${scaledSize}px`}>
      <g id="web" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="04-contacts-open">
          <g id="Group" transform={`scale(${scale})`}>
            <g id="Stacked-Group-3">
              <g id="icon/soc-instagram-copy">
                <path d="M7.31488067,18 L10.8053814,18 L10.8053814,8.9222395 L13.2401965,8.9222395 L13.5,5.88413686 L10.8053814,5.88413686 L10.8053814,4.15318818 C10.8053814,3.43623639 10.944642,3.15241058 11.6109967,3.15241058 L13.5,3.15241058 L13.5,0 L11.083154,0 C8.48586804,0 7.31488067,1.18662519 7.31488067,3.46034215 L7.31488067,5.88413686 L5.5,5.88413686 L5.5,8.96111975 L7.31488067,8.96111975 L7.31488067,18 Z" fill={fillColor} id="Fill-1"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Facebook;